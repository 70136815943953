const appConfig = {
  // app title
  name: "TEO Blockchain Explorer",
  // app description
  description:
    "TEO Explorer allows you to explore and search the TEO blockchain for transactions, addresses and blocks",
  // app keywords
  keywords: "Corex, TEO, explorer, search, blockchain, crypto, currency, блокчэйн",
  // apollo client settings
  apollo: {
    // list of providers. if one of them is unavailable, another is randomly picked
    providers: [
      {
        http: 'https://graphql.corexchain.io/graphql',
        //http: 'http://192.168.55.191:8080/graphql',
        // for subscriptions
        ws: ''
      },
    ],
    // index into providers array of default provider or 'random' - takes index randomly
    defaultProviderIndex: "random"
  },
  //
  useTestnet: false,
  // use 'hash' mode in vue router
  routerHashMode: false,
  // testnet config
  testnet: {
    // list of providers. if one of them is unavailable, another is randomly picked
    providers: [
      {
        http: 'http://157.245.49.8080/api',
        // for subscriptions
        ws: '',
      },
    ],
  },
  // progressive web application
  usePWA: true,
  // pwa settings
  pwa: {
    // name used in pwa manifest
    name: "TEO Blockchain Explorer"
  },
  // default options for production build
  build: {
    // output dir for production build
    outputDir: "dist"
  },
  // downtime threshold in seconds. downtime values less than this threshold will be displayed as 0
  downtimeThreshold: 10
};

if (appConfig.useTestnet) {
  appConfig.apollo.providers = appConfig.testnet.providers;
}

// scss variables prepended to every scss file
appConfig.scssData = `
    @import "src/assets/scss/vars";
`;

module.exports = appConfig;
