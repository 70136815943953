<template>
  <ul class="f-social-media-links no-markers">
<!--    <li>-->
<!--      <a href="https://github.com/Fantom-Foundation" rel="nofollow" target="_blank"-->
<!--         title="Fantom on Github">-->
<!--        <icon data="@/assets/svg/social-media/github.svg" height="24" width="24"></icon>-->
<!--      </a>-->
<!--    </li>-->
  </ul>
</template>

<script>
export default {}
</script>

<style lang="scss">
.f-social-media-links {
  --f-social-media-links-color: #{$secondary-color-lighter};

  color: var(--f-social-media-links-color);

  li {
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      padding-inline-end: 24px;
    }

    @include links() {
      color: inherit;
      transition: color $transition-length ease;
    }

    a:not(.btn):hover {
      color: #fff;
    }
  }

  .linkedin {
    position: relative;
    top: -3px;
  }
}
</style>
