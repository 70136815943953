var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delegation-list-dt"},[(!_vm.dBlockListError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.dHasNext,"loading":_vm.cLoading,"first-m-v-column-width":"5","infinite-scroll":"","fixed-header":""},on:{"loader-visibility":_vm.onLoaderVisibility},scopedSlots:_vm._u([{key:"column-address",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(value))+" ")])],1)]):[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(value))+" ")])]]}},{key:"column-amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})]]}}],null,false,3848207955)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.dBlockListError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }